<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            label="집행년도"
            name="year"
            type="year"
            default="today"
            v-model="searchParam.budgetYear"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            type="search"
            :editable="editable"
            label="부서"
            name="budgetDeptCd"
            :isFirstValue="false"
            v-model="searchParam.budgetDeptCd"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="예산실적 목록"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :columnSetting="false"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'budget-compare',
  data() {
    return {
      grid: {
        columns: [
          
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            style: 'width:150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'budgetYear',
            field: 'budgetYear',
            label: '집행년도',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'budgetItemName',
            field: 'budgetItemName',
            label: '항목',
            style: 'width:250px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'budgetPlanAmt',
            field: 'budgetPlanAmt',
            label: '예산편성금액(원)',
            style: 'width:150px',
            align: 'right',
            type: 'cost',
            sortable: true,
          },
          {
            name: 'budgetResultAmt',
            field: 'budgetResultAmt',
            label: '예산집행금액(원)',
            style: 'width:150px',
            align: 'right',
            type: 'cost',
            sortable: true,
          },
          {
            name: 'exchange',
            field: 'exchange',
            label: '잔액(A - B)',
            style: 'width:150px',
            align: 'right',
            type: 'cost',
            sortable: true,
          },
          {
            name: 'exchangePercent',
            field: 'exchangePercent',
            label: '예산대비 비율(%)',
            style: 'width:150px',
            align: 'right',
            type: 'cost',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        budgetStatusCd: 'BS00000005',
        budgetDeptCd: '',
        budgetYear: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sai.budget.compare.list.url;
      this.searchParam.budgetYear = this.$comm.getThisYear();
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data.map(item => ({
          ...item,
          budgetItemName: this.$language(item.budgetItemName)
        }));
      },);
    },
  }
};
</script>
